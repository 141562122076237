import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Oban_Whiskey_Domain } from "../../constant";
import WhereToBuySection from "./WhereToBuySection";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";

const Whiskies = ({ whiskeyKey }) => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main whiskies">
        <Helmet>
          <title>{whiskeyKey.title}</title>
          <meta name="description" content={whiskeyKey.metaDescription} />
          <meta property="og:title" content={whiskeyKey.title} />
          <meta
            property="og:description"
            content={whiskeyKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + whiskeyKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={whiskeyKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + whiskeyKey.url}`}
          />
          <meta name="twitter:title" content={whiskeyKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <section className="hero products firstSection">
          <Container>
            <Row>
              <Col sx={12}>
                <div className="hero-heading-wrapper">
                  <h1 className="large-hero-heading text-dark pb-4">
                    Some say we have a<br />
                    winning spirit.
                  </h1>
                </div>
                <div className="hero-heading-wrapper">
                  <p className="kicker text-dark">
                    In 2017, we received a 94-point rating in the Ultimate
                    Spirits Challenge. Here’s to another 200 years of not
                    changing a thing.
                    <br />
                  </p>
                </div>
                <div className="p-5 d-none d-md-flex dataDiv"></div>
                <div className="oban-family-image">
                  <ParallaxProvider>
                    <Parallax
                      style={{ transform: "translate3d(20px, 0px, 0px)" }}
                      scale={[1, 1.1]}
                    >
                      <Image
                        src={`${Oban_Whiskey_Domain}/assets/images/oban_family-updated.png`}
                        alt="Oban Family"
                        data-w-id="59a6897d-c34b-5654-68a5-8efc562c70a2"
                        className="oban-family img-fluid"
                      />
                    </Parallax>
                  </ParallaxProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="hero products secondSection">
          <div className="container heading">
            <h2 className="text-dark">Our whiskies</h2>
          </div>
          <div className="d-flex dataDiv reverse">
            <div className="dscDiv">
              <div className="leftdscDiv">
                <div className="half-container">
                  <h2 className="preheader-text">
                    <span className="preheader">OBAN</span>
                    <br />
                    14 Years Old
                  </h2>
                  <p className="description">
                    Flavours of rich fruits, smoky dryness, honeyed spice, and a
                    whiff of sea salt distinguish this full-bodied Highland
                    malt.
                    <br />
                  </p>
                  <Button
                    className="button"
                    variant="dark"
                    size="lg"
                    onClick={executeScroll}
                  >
                    Buy Now
                  </Button>
                </div>
                <div className="anchored-cta-wrapper">
                  <Link to="/whiskies/14-years-old" className="inline-link">
                    View product
                  </Link>
                </div>
              </div>
            </div>
            <div className="videoDiv">
              <div className="product-cell product-video right">
                <div className="background-video">
                  <video className="full-video" loop autoPlay muted>
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/14.webm`}
                      type="video/webm"
                    />
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/14.mp4?s=4695cd0084a3b6437bc957c9cd0634e89f3b0213&profile_id=175`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex dataDiv">
            <div className="videoDiv">
              <div className="product-cell product-video half-container">
                <div className="background-video">
                  <video className="full-video" loop autoPlay muted>
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/18.mp4`}
                      type="video/mp4"
                    />
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/18.mp4?s=4695cd0084a3b6437bc957c9cd0634e89f3b0213&profile_id=175`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
            <div className="dscDiv">
              <div className="righrdscDiv">
                <div className="half-container left">
                  <h2 className="preheader-text">
                    <span className="preheader">OBAN</span>
                    <br />
                    18 Years Old
                  </h2>
                  <p className="description">
                    An elegantly matured single malt that balances opulent fruit
                    flavours with rich peat smoke and spicy wood, culminating in
                    a long, smooth, finish.
                    <br />
                  </p>
                  <div className="limited-edition-legend">Limited Edition</div>
                  <Button
                    className="button"
                    variant="dark"
                    size="lg"
                    onClick={executeScroll}
                  >
                    {" "}
                    Buy Now{" "}
                  </Button>
                </div>
                <div className="anchored-cta-wrapper left">
                  <Link to="/whiskies/18-years-old" className="inline-link">
                    View product
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex dataDiv reverse">
            <div className="dscDiv">
              <div className="leftdscDiv">
                <div className="half-container">
                  <h2 className="preheader-text">
                    <span className="preheader">OBAN</span>
                    <br />
                    Little Bay
                  </h2>
                  <p className="description">
                    Bold and complex. Our whisky boasts luscious notes of
                    Christmas pudding, citrus, and spice, with a dark-chocolate
                    finish.
                    <br />
                  </p>
                  <Button
                    className="button"
                    variant="dark"
                    size="lg"
                    onClick={executeScroll}
                  >
                    Buy Now
                  </Button>
                </div>
                <div className="anchored-cta-wrapper">
                  <Link to="/whiskies/little-bay" className="inline-link">
                    View product
                  </Link>
                </div>
              </div>
            </div>
            <div className="videoDiv">
              <div className="product-cell product-video half-container">
                <div className="background-video">
                  <video className="full-video" loop autoPlay muted>
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/LB.mp4`}
                      type="video/mp4"
                    />
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/LB.mp4?s=4695cd0084a3b6437bc957c9cd0634e89f3b0213&profile_id=175`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex dataDiv">
            <div className="videoDiv">
              <div className="product-cell product-video">
                <div className="background-video">

                  {/* <video className="full-video" loop autoPlay muted>
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/DE.mp4`}
                      type="video/mp4"
                    />
                    <source
                      src={`${Oban_Whiskey_Domain}/assets/videos/DE.mp4?s=4695cd0084a3b6437bc957c9cd0634e89f3b0213&profile_id=175`}
                      type="video/mp4"
                    />
                  </video> */}



                  <img src={`${Oban_Whiskey_Domain}/assets/images/distillers-updated-collection.png`}
                   alt="Oban Family" 
                    className="full-video">
                  
                  </img>
                </div>
              </div>
            </div>
            <div className="dscDiv">
              <div className="righrdscDiv">
                <div className="half-container">
                  <h2 className="preheader-text">
                    <span className="preheader">OBAN</span>
                    <br />
                    Distillers Edition
                  </h2>
                  <p className="description">
                    A second maturation in Montilla Fino casks develops
                    luxurious flavours of butterscotch, toffee, and caramel with
                    a backbone of sea salt and a smoky-sweet finish.
                    <br />
                  </p>
                  <Button
                    className="button"
                    variant="dark"
                    size="lg"
                    onClick={executeScroll}
                  >
                    Buy Now
                  </Button>
                </div>
                <div className="anchored-cta-wrapper left">
                  <Link
                    to="/whiskies/distillers-edition"
                    className="inline-link"
                  >
                    View product
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={myRef} className="where-to-buy-section">
          <WhereToBuySection />
        </section>
        <section className="whisky-last-section home-last-section">
          <Container>
            <Row>
              <Col md={6} xs={12}>
                <div className="text-content">
                  <h3>A process — and products — perfected over time.</h3>
                  <p className="mb-4">
                    A five-day fermentation ensures the rich flavours of the
                    malted barley develop fully, at their own pace.
                  </p>
                  <p>
                    It’s worked for over 200 years. We think it’ll be good for a
                    few more.
                  </p>
                  <Link className="inline-link" to="/heritage">
                    Explore our heritage
                  </Link>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="footer-image home">
                  <Image
                    src="/assets/images/process_img-p-500.jpeg"
                    alt="process"
                    width="450"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Whiskies;